.container {
	text-align: center;
}

.h1Link:hover,
.h1Link:visited:hover {
	color: inherit;
}

.h1Link:visited {
	color: inherit;
}

.tipText {
	text-align: center;
	font-style: italic;
	font-size: 13px;
}

@media only screen and (max-width: 768px) {
	.tipText {
	  display: none;
	}
	.description {
		padding: 0px 20px;
	}
  }

.ReactTable {
	margin: 0 auto;
	max-width: 998px;
}

.rt-tr { /* React table-Table row (each row in the table) */
	align-items: center;
}

.thumbnail {
	box-shadow: 2px 2px 3px rgba(0,0,0,0.4);
	float: right;
}

.title {
	text-align: left;
	float: left;
	padding-left: 20px;
}

a {
	text-decoration: none;
}

a:hover,
a:visited:hover {
	color: red;
}

a:visited {
	color: rgb(92, 0, 92);
}

.yearPublished {
	font-size: 11px;
	color: gray;
}

.ratingContainer {
	display: flex;
	justify-content: center;
}

.averageRating {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	width: 45px;
	border-radius: 12px;
	color: white;
	font-weight: bold;
}

#footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
	margin: 0 auto;
	max-width: 998px;
}