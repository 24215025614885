.search {
    text-align: center;
}

@media only screen and (max-width: 768px) {
	.search {
	  padding-bottom: 20px;
	}
  }

#searchBox {
    margin-right: 5px;
    border-radius: 4px;
    border-color: black;
    padding: 9px;
    text-align: center;
}

button {
    background: #4479BA;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
	border: solid 1px #20538D;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    transition-duration: 0.2s;
}

button:hover, button:focus{
    background: #356094;
	border: solid 1px #2A4E77;
	text-decoration: none;
}

button:active {
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
	background: #2E5481;
	border: solid 1px #203E5F;
}